import * as React from "react"
import './App.css';
import fbombTheme from "./theme"
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid, Container,
} from "@chakra-ui/react"
import {ColorModeSwitcher} from "./ColorModeSwitcher"
import Buzzsprout from "./hooks/buzzsprout";
import {Logo} from "./Logo";
export const App = () => {
    Buzzsprout(1017913);

    return (
        <ChakraProvider theme={fbombTheme}>
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3}>
                    <ColorModeSwitcher justifySelf="flex-end"/>
                    <VStack spacing={8}>
                        <Container maxW='lg' centerContent>
                            <Logo />
                        </Container>
                        <div id="buzzsprout-large-player">
                            <div className="episode">
                                <iframe id="player_iframe" src="https://www.buzzsprout.com/1017913?artist=&amp;client_source=large_player&amp;iframe=true&amp;limit=5&amp;referrer=https%3A%2F%2Fwww.buzzsprout.com%2F1017913.js%3Fartist%3D%26container_id%3Dbuzzsprout-large-player-limit-5%26limit%3D5%26player%3Dlarge" width="100%" height="375"   title="My Average Greatness"></iframe>
                            </div>
                        </div>
                    </VStack>
                </Grid>
            </Box>
        </ChakraProvider>
    )
}
