import * as React from "react"

import {
    Flex, FlexboxProps,
    forwardRef,
    Image, Text
} from "@chakra-ui/react";
import { ReactComponent as FBomb } from "./assets/img/fbomb.svg";

export const Logo = forwardRef<FlexboxProps, 'div'>((props: FlexboxProps, ref) => {

    return <Flex direction='column' w='100%' ref={ref} {...props} >
        <FBomb />
    </Flex>

})
