import { useEffect } from 'react';

const Buzzsprout = (id: number) => {
    useEffect(() => {
        console.log('buzz called')
        const script = document.createElement('script');

        script.src = `https://www.buzzsprout.com/${id}.js?artist=&container_id=buzzsprout-large-player-limit-5&limit=5&player=large`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [id]);
};

export default Buzzsprout;
