import { extendTheme, ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: true,
}

// const theme = extendTheme({ config })

const theme = extendTheme({
    initialColorMode: 'light',
    semanticTokens: {
        colors: {
            text: {
                default: '#16161D',
                _dark: '#ade3b8',
            },
            primary: {
                default: '#0096c1',
                _dark: '#6db1c4',
            },
            secondary: {
                default: '#004a60',
                _dark: '#ddefff',
            },
        }}})

export default theme;
